<template>
  <div class="loader-component">
    <svg class='loader' viewBox='0 0 10 10'>
      <path id='g' d='M 0 0 L 10 0 L 10 10 L 0 10 L 0 0' stroke='#0033A0' fill="none" stroke-linecap='round' />
    </svg>
    <div v-for="(record, recordIndex) in text" :key="recordIndex + 'recordIndex'">{{ record }}</div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: ['text'],
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style>
@keyframes loader-g {
  from {
    stroke-dasharray: 10 10;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dasharray: 10 10;
    stroke-dashoffset: 100;
  }
}

#g {
  animation: loader-g 4s linear infinite;
}
</style>